import { autoinject, bindable, bindingMode, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { MyHttpApi, PrivilegeItem } from 'utils/api';
import { setCorporation } from 'utils/corporation-util';

let idGen = 0;

@autoinject
export class ClientSelect2CustomElement {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) value?: number = undefined;
  @bindable({ defaultBindingMode: bindingMode.toView }) disabled?: boolean;
  @bindable({ defaultBindingMode: bindingMode.toView }) required?: boolean;

  private readonly id = "client-select2-" + ++idGen;
  private content?: PrivilegeItem[] = [];
  private search = "";
  private searchMode = false;

  constructor(private readonly api: MyHttpApi, private readonly i18n: I18N, private readonly element: Element) {
  }

  detached() {
	document.removeEventListener("click", this.clickHandler);
 }

 async attached() {
	this.content = await this.api.privilegeListClient();
	if (!this.value && this.content.length) {
     this.value = this.content[0].id;
	}
  document.addEventListener("click", this.clickHandler.bind(this));
 }

	clickHandler(e: Event) {
		if(!(this.element.compareDocumentPosition(e.target as Node) &
				Node.DOCUMENT_POSITION_CONTAINED_BY)) {
			this.searchMode = false;
		}
	}

  @computedFrom("value", "content")
  get selectedItem() {
    return this.content?.find(x => x.id === this.value);
  }

  /** If we have only one (even partial) match, select it. */
  async focusEnd() {
    if (!this.content) {
      return;
    }
    let exactMatch = this.content.find(c => c.name.toLowerCase() === this.search.toLowerCase());
    let results = this.content.filter(c => c.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1);
    if (exactMatch) {
      this.select(exactMatch);
    } else if (results.length === 1) {
      this.select(results[0]);
    }
  }

  focusStart() {
    if (this.content?.length && !this.disabled) {
      this.searchMode = true;
    }
  }

  select(item: PrivilegeItem) {
    this.value = item.id;
    setCorporation(this.selectedItem);
    if (this.value) {
      this.toggleSearch();
    }
  }

  toggleSearch() {
    if (this.disabled) {
      return;
    }
    if (this.value || !this.required) {
      this.searchMode = !this.searchMode;
      this.search = "";
    }
  }

  clear() {
    if (this.disabled) {
      return;
    }
    this.value = undefined;
    this.toggleSearch();
  }

  @computedFrom("content", "search")
  get dynamicContent() {
    let s = this.search.toLowerCase();
    if (!s || !this.content) {
      return this.content;
    }
    return this.content.filter(x => x.name.toLowerCase().indexOf(s) !== -1);
  }

  @computedFrom("value", "content", "required")
  get typeAndName() {
    if (!this.content) {
      return "";
    }
    let found = this.content.find(c => c.id === this.value);
    // * If we have bad localStorage-value, try to select any value, that we actually have
    if (this.content.length && !found) {
      this.select(this.content[0]);
      this.searchMode = false;
      found = this.content.find(c => c.id === this.value);
    }
    if (this.required) {
      let warning = "";
      let el = this.element.getElementsByTagName("input")[0];
      if (!found) {
        warning = this.i18n.tr("common.required");
      }
      if (el) {
        el.setCustomValidity(warning);
        el.reportValidity();
      }
    }
    if (!found) {
      return this.i18n.tr("common.empty");
    }
    return this.i18n.tr("PrivilegeType.CLIENT") + ": " + found.name;
  }
}
