import { autoinject, bindable, bindingMode, computedFrom } from 'aurelia-framework';
import Tagify, { TagData } from '@yaireo/tagify';
import '@yaireo/tagify/dist/tagify.css';
// @ts-ignore
import DragSort from '@yaireo/dragsort';
import '@yaireo/dragsort/dist/dragsort.css';

/**
 * ! HOX - will not work well with Date types, avoid these in value/labelKey
 */
@autoinject
export class BelAuTagInputCustomElement {
	@bindable({ defaultBindingMode: bindingMode.twoWay }) selectedValues: any[] = [];
	@bindable({ defaultBindingMode: bindingMode.toView }) choices: any[] = [];
	@bindable({ defaultBindingMode: bindingMode.oneTime }) labelKey = "name";
	@bindable({ defaultBindingMode: bindingMode.oneTime }) valueKey = "id";
	@bindable({ defaultBindingMode: bindingMode.toView }) disabled = false;
	@bindable({ defaultBindingMode: bindingMode.oneTime }) multiple = true;
	@bindable({ defaultBindingMode: bindingMode.oneTime }) allowDuplicates = false;

	private tagify?: Tagify = undefined;
	constructor(private readonly element: Element) { }

	@computedFrom("choices.length")
	get choicesObserver() {
		if (this.choices?.length && this.tagify) {
			this.tagify.whitelist = this.whiteList;
		}
		return "";
	}

  /** Just mapped choice with special keys id and value */
	get whiteList() {
		return this.choices.map(c => {
			const choice = { ...c };
			choice.value = choice[this.labelKey];
			choice.id = choice[this.valueKey];
			return choice;
		});
	}

  /** make choices to be minimised set of id/value instead of full object */
	@computedFrom("selectedValues.length")
	get values(): string {
		return this.selectedValues ? JSON.stringify(this.selectedValues.map(c => {
			const choice: TagData = { id: c[this.valueKey], value: c[this.labelKey] };
			return choice;
		})) : '[]';
	}
  /** And map the minimised values back to originals */
	set values(values: string) {
		const internalValue: TagData[] = JSON.parse(values || '[]');
		this.selectedValues = internalValue.map(iv => this.choices.find(c => c[this.labelKey] == iv.value)!);
	}

	detached() {
		this.tagify?.destroy();
	}

	attached() {
		const element = this.element.querySelector(".bel-au-tag-input") as HTMLInputElement;
		if (element) {
			this.tagify = new Tagify(element, {
				whitelist: this.whiteList,
				mode: this.multiple ? null : 'select',
				tagTextProp: "value",
				autoComplete: {
					enabled: false
				},
				dropdown: {
					enabled: 0,
					includeSelectedTags: true,
					closeOnSelect: this.multiple || false,
				},
				duplicates: this.allowDuplicates,
				enforceWhitelist: true,
			});

			new DragSort(this.tagify.DOM.scope, {
				selector: '.' + this.tagify.settings.classNames.tag,
				callbacks: {
					dragEnd: () => {
						this.onDragEnd();
					}
				}
			});
		}
	}

	onDragEnd() {
		this.tagify?.updateValueByDOMTags();
	}
}
